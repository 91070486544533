import Vue from 'vue'
import Router from 'vue-router'
import AppLayout from '../components/admin/AppLayout'
import axios from 'axios'
import store from '../store'

Vue.use(Router)

const EmptyParentComponent = {
  template: '<router-view></router-view>',
}

const router = new Router({
mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes: [
    {
      path: '*',
      redirect: { name: 'dashboard' },
    },  
    {
      path: "/terminal",
      name: "terminal",
      component: () => import('../components/assets/views/Core/RemoteTerminal')
    },
    {
      path: '/login',
      component: () => import('../components/auth/Login.vue'),
      name: "login",
    },
    {
      name: 'default',
      path: '/',
      component: AppLayout,
      redirect: { name: 'dashboard' },
      children: [
        {
          name: 'dashboard',
          path: '/',
          default: true,
          meta: { requiresAuth: true },
          component: () => import('../components/dashboard/Dashboard.vue'),
        },

        {
          name: "pricing",
          path: "/pricing",
          meta: { requiresAuth: true },
          component: () => import('../components/pricing/Pricing.vue'),
        },

        {
          name: "faq",
          path: "/faq",
          meta: { requiresAuth: true },
          component: () => import('../components/faq/FAQ.vue'),
        },

        {
          name: "datapools",
          path: "/datapools",
          meta: { requiresAuth: true },
          component: () => import('../components/datapools/Main.vue'),
        },

        {
          name: "fleets",
          path: "/fleets",
          meta: { requiresAuth: true, requiredGroups: ["fleet_enabled"] },
          component: () => import('../components/fleets/FleetList.vue'),
        },

        {
          name: "developer",
          path: "/developer",
          meta: { requiresAuth: true },
          component: () => import('../components/developer/Main.vue'),
        },

      ],
    },

    {
      name: "registerAssetShortcut",
      path: "/r/:sn",
      meta: { requiresAuth: true },
      beforeEnter(to, from, next) {
        const sn = to.params.sn
        next({ name: 'assetList', params: { register: sn } })
      }
    },

    {
      name: "assetsRelated",
      path: "/assets",
      component: AppLayout,
      redirect: { name: 'assetList' },
      children: [
        {
          name: 'assetList',
          path: '/',
          meta: { requiresAuth: true },
          component: () => import('../components/assets/AssetList.vue'),
        },
        {
          name: 'assetsMainView',
          path: ':assetID',
          meta: { requiresAuth: true },
          component: () => import('../components/assets/views/MainView.vue'),
        },
        {
          name: 'assetsCoreView',
          path: ':assetID/core',
          meta: { requiresAuth: true },
          component: () => import('../components/assets/views/Core/Main.vue'),
        },
        {
          name: 'createCoreDevice',
          path: 'create-core-device',
          meta: { requiresAuth: true },
          component: () => import('../components/assets/views/CreateCoreDevice.vue'),
        },
      ]
    },

    {
      name: 'coreRelated',
      path: '/core',
      component: AppLayout,
      redirect: { name: 'devicelist' },
      children: [
        {
          name: 'devicelist',
          path: '/',
          meta: { requiresAuth: true },
          component: () => import('../components/core/DeviceList.vue'),
        },
        {
          name: 'coreDeployments',
          path: 'deployments',
          meta: { requiresAuth: true },
          component: () => import('../components/core/deployments/Main.vue'),
        },
        {
          name: 'coreLocations',
          path: 'locations',
          meta: { requiresAuth: true },
          component: () => import('../components/core/locations/Main.vue'),
        }
      ],
    },

    {
      name: 'billing',
      path: '/billing',
      component: AppLayout,
      redirect: { name: 'billing' },
      children: [
        {
          name: 'billingDetails',
          path: '/',
          meta: { requiresAuth: true },
          component: () => import('../components/billing/Main.vue'),
        },
        {
          name: 'addcredits',
          path: 'addcredits',
          meta: { requiresAuth: true },
          component: () => import('../components/billing/Main.vue'),
        },
        {
          name: 'completePayment',
          path: 'complete_*',
          meta: { requiresAuth: true },
          component: () => import('../components/billing/Main.vue'),
        }
      ],
    },
    {
      path: '/network',
      component: AppLayout,
      children: [
        {
          name: 'operators',
          path: 'operators',
          meta: { requiresAuth: true },
          component: () => import('../components/pricing/Networks.vue'),
        }
      ],
    },

    {
      path: '/usage',
      component: AppLayout,
      children: [
        {
          name: 'usage',
          path: '/',
          meta: { requiresAuth: true },
          component: () => import('../components/usage/Main.vue'),
        },
        {
          name: 'summary',
          path: 'summary',
          meta: { requiresAuth: true },
          component: () => import('../components/usage/Summary.vue'),
        }
      ],
    },
    {
      path: '/masa',
      component: AppLayout,
      meta: { requiresAuth: true ,requiredPermissions: ["backoffice"]},
      children: [
        {
          name: 'backoffice',
          path: '/',
          meta: { requiresAuth: true, requiresBackofficeToken:true },
          component: () => import('../components/backoffice/Backoffice.vue'),
        
    },
    {
      name:"BoAccess",
      path:'access',
      meta: { requiresAuth: true, requiresBackofficeToken:false },
      component: () => import('../components/backoffice/BoAccess.vue'),
      
    },
    {
      name:'BoAssetDetails',
      path:'asset/:assetID',
      meta: { requiresAuth: true, requiresBackofficeToken:true },
      component: () => import('../components/backoffice/BoAssetDetails.vue'),
    },
    {
      name: 'customer',
      path: 'customers/:id',
      meta: { requiresAuth: true, requiresBackofficeToken:true  },
      component: () => import('../components/backoffice/BoCustomer.vue'),
    },
    {
      name:'BoLogs',
      path:'logs',
      meta: { requiresAuth: true, requiresBackofficeToken:true },
      component: () => import('../components/backoffice/BoLogs.vue'),
    }
  ],
    },

  ],
})


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {

    if(window.location.hash.includes("access_token")){
      next({name: "login", params: {auth0Data: window.location.hash}})
      return
    }

    if (!store.getters.isAuthenticated) {
      // User info does not exist, fetch it
      axios.get('/auth/user_info', { withCredentials: true, baseURL: process.env.VUE_APP_API_ROOT })
        .then((response) => {
          const userInfo = response.data;
          store.commit('AUTH_SUCCESS', userInfo);
          next();
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
          next({ name: "login", params: { redirect_url: to.fullPath } });
        });

      return; // Stop the navigation until user info is fetched
    } 
    else if (store.getters.isAuthenticated) {

      if (to.matched.some(m => m.meta.requiredGroups)) {
        let customerGroups = store.getters.customer.groups || [];
        if (!to.matched.some(m => m.meta.requiredGroups && m.meta.requiredGroups.every(el => customerGroups.includes(el)))) {
          next({ name: "dashboard" });
          return;
        }
      }
      if (to.matched.some(m => m.meta.requiredPermissions)) {
        let customerPermissions = store.getters.customer.permissions || [];
        if (!to.matched.some(m => m.meta.requiredPermissions && m.meta.requiredPermissions.every(el => customerPermissions.includes(el)))) {
          next({ name: "dashboard" });
          return;
        }
      }
      // authenticated and has required permissions & groups but need backoffice token
      if (to.matched.some(m => m.meta.requiresBackofficeToken))  {
        if (!store.getters.backofficeToken || !store.getters.decodedBackofficeToken || (new Date() / 1000) > store.getters.decodedBackofficeToken.exp) {
          next({ name: "BoAccess", params: { redirect_url: to.fullPath } });
          return;
        }
      }
      // has a valid backoffice token, prevent going back to BoAccess
      if(to.name === "BoAccess" && store.getters.backofficeToken && store.getters.decodedBackofficeToken && (new Date() / 1000) < store.getters.decodedBackofficeToken.exp){
        router.go(-1)
        return
      }

      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})

// logout backoffice before leaving backoffice routes
router.afterEach((to, from) => {
  if (from.matched.some(m => m.meta.requiresBackofficeToken) && !to.matched.some(m => m.meta.requiresBackofficeToken)) {
    store.commit("setFilters", {
      tag: [],
      type: "",
      simStatus: "",
    });
    store.commit("setSearchQuery", "");
    store.dispatch('logout_backoffice')
  }
})

export default router