<template>
  <div id="app" class="app">
    <sf-header> </sf-header>
    <router-view/>
  </div>
</template>

<script>
import SfHeader from "../components/header/Header.vue"

export default {
  name: 'app',
  components: {SfHeader}
}
</script>

<style lang="scss">
@import "../sass/main.scss";

body {
  height: 100%;

  #app {
    height: 100%;
  }
}
</style>
