import axios from 'axios';
import store from '../store';
import router from '../router';


const customAxios = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
});


customAxios.interceptors.request.use((config) => {

  config.withCredentials = true;
  
  const backofficeToken = store.getters.backofficeToken;
  if (backofficeToken != null) {
    config.headers['X-Backoffice-Token'] = backofficeToken;
  }

  return config;
}, (error) => error);


customAxios.interceptors.response.use((response) => response,
  ({ response }) => {
    if (response.statusText === 'UNAUTHORIZED'
            || response.status === 422
            || response.status === 401) {
      store.dispatch('auth_logout')
    }
    if (response.status === 403 && router.currentRoute.meta.requiresBackofficeToken) {
      store.dispatch("logout_backoffice")
      router.push({ name: "BoAccess", params: { redirect_url: router.currentRoute.fullPath } })
    }
    return response;
  });

export default customAxios;
