// Polyfills
import "es6-promise/auto";
import "babel-polyfill";
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import { ColorThemePlugin } from "vuestic-ui/src/services/ColorThemePlugin";
import store from "../store/index";
import router from "../router/index";
import VuesticPlugin from "vuestic-ui/src/components/vuestic-plugin";
import "../i18n/index";
import YmapPlugin from "vue-yandex-maps";
import VueClipboard from "vue-clipboard2";
import VeeValidate from "vee-validate";
import SixfabLogo from "../customComponents/Logo.vue";
import LoadingDots from "../customComponents/LoadingDots.vue";
import LoadingSpinner from "../customComponents/LoadingSpinner.vue";
import BetaBadge from "../customComponents/BetaBadge.vue";
import _axios from "./Api";
import VueAxios from "vue-axios";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import flatPickr from "vue-flatpickr-component";
import "../sass/flatpickr.min.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { 
  faCheckCircle, 
  faExclamationCircle, 
  faExternalLinkAlt, 
  faPowerOff, 
  faQuestionCircle, 
  faTerminal, 
  faSort, 
  faStepBackward, 
  faStepForward,
  faPlus,
  faCogs,
  faCubes,
  faCreditCard,
  faExclamationTriangle,

} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VModal from "vue-js-modal";
import VTooltip from "v-tooltip";
import PulseLoader from "../customComponents/PulseLoader";
import PacmanLoader from "../customComponents/PacmanLoader";
import SfIcons from "../customComponents/SfIcons";
import SfButton from "../customComponents/SfButton";
import SfCard from "../customComponents/SfCard";
import SfTable from "../customComponents/SfTable";
import SfSearch from "../customComponents/SfSearch";
import SfFilter from "../customComponents/SfFilter";
import SfSelect from "../customComponents/SfSelect";
import SfSlider from "../customComponents/SfSlider";
import SfProgress from "../customComponents/SfProgress";
import SfProfileCard from "../customComponents/SfProfileCard";
import VueToast from 'vue-toast-notification';
import Popover from 'vue-js-popover'
import 'vue-toast-notification/dist/theme-sugar.css';
import initAuth0Client from "../auth";

import "../metrics";

library.add(
  faCheckCircle, 
  faExclamationCircle, 
  faExternalLinkAlt, 
  faPowerOff, 
  faQuestionCircle, 
  faTerminal, 
  faSort, 
  faStepBackward, 
  faStepForward,
  faPlus,
  faCogs,
  faCubes,
  faCreditCard,
  faExclamationTriangle
);

// NOTE: workaround for VeeValidate + vuetable-2
Vue.use(VeeValidate, { fieldsBagName: "formFields" });

Vue.use(VueAxios, _axios);
Vue.use(VuesticPlugin);
Vue.use(VModal);
Vue.use(YmapPlugin);
Vue.use(VueClipboard);
Vue.use(VTooltip);
Vue.use(VueToast)
Vue.use(Popover, { tooltip: true })
Vue.prototype.$auth0 = await initAuth0Client()

import { loadStripe } from "@stripe/stripe-js";

loadStripe(process.env.VUE_APP_STRIPE_KEY).then((resp) => {
  Vue.prototype.$stripe = resp
});

Vue.component("fa-icon", FontAwesomeIcon);
Vue.component("loading", LoadingDots);
Vue.component("loading-spinner", LoadingSpinner);
Vue.component("beta-badge", BetaBadge);
Vue.component("logo", SixfabLogo);
Vue.component("VueSlider", VueSlider);
Vue.component("flatPickr", flatPickr);
Vue.component("pulse-loader", PulseLoader);
Vue.component("pacman-loader", PacmanLoader);
Vue.component("sf-icons", SfIcons)
Vue.component("sf-button", SfButton)
Vue.component("sf-card", SfCard)
Vue.component("sf-table", SfTable)
Vue.component("sf-search", SfSearch)
Vue.component("sf-filter", SfFilter)
Vue.component("sf-select", SfSelect)
Vue.component("sf-slider", SfSlider)
Vue.component("sf-progress", SfProgress)
Vue.component("sf-profile-card", SfProfileCard)

Vue.use(ColorThemePlugin, {
  // Add or change theme colors here
  themes: {
    // primary: '#f06595',
    // blurple: '#7289DA',
  },
});

router.beforeEach((to, from, next) => {
  store.commit("setLoading", true);
  next();
});

router.afterEach((to, from) => {
  store.commit("setLoading", false);
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
