import axios from '../../app/Api';
import router from '../../router';
import jwtDecoder from 'jwt-decode'
import initAuth0Client from "../../auth"

const state = {
  userInfo: null,
  backofficeToken: localStorage.getItem('t-backoffice') || '',
  status: '',
  accountSuspended: undefined,
};


const getters = {
  isAuthenticated: (state) => !!state.userInfo,
  authStatus: (state) => state.status,
  backofficeToken: (state) => state.backofficeToken,
  decodedBackofficeToken: (state) => {
    try{
      return jwtDecoder(state.backofficeToken)
    }
    catch (e){
      return undefined
    }
  },
  customer: (state) => state.userInfo,
  isAccountSuspended: (state) => state.accountSuspended
};

const mutations = {
  AUTH_REQUEST: (state) => {
    state.status = 'loading';
  },
  AUTH_SUCCESS: (state, userInfo) => {
    state.status = 'success';
    state.userInfo = userInfo;
  },
  AUTH_ERROR: (state) => {
    state.status = 'error';
  },
  AUTH_LOGOUT: (state) => {
    state.userInfo = null;
  },

  updateSuspensionStatus: (state, value) => {
    state.accountSuspended = !!value
  },

  BACKOFFICE_LOGOUT: (state) => {
    state.backofficeToken = '';
  },
  BACKOFFICE_LOGIN: (state, token) => {
    state.backofficeToken = token;
  }
};

const actions = {
  auth_login: ({ commit, dispatch }, user) => new Promise(async (resolve, reject) => { // The Promise used for router redirect in login
    commit('AUTH_REQUEST');
    try {
      const resp = await axios({ url: 'auth/login', data: user, method: 'POST' })

      if (resp.status !== 200) {
        reject(resp);
        return;
      }
      const user_resp = await axios({ url: 'auth/user_info', method: 'GET'})
      commit('AUTH_SUCCESS', user_resp.data);
      resolve(resp);
    
    } catch (err) {
      commit('AUTH_ERROR', err);
      reject(err);
    }
  }),

  auth_logout: ({ commit, dispatch }, isManuel = false) => new Promise((resolve, reject) => {
    const clear_tokens = async () => {
      commit('AUTH_LOGOUT');
      commit ('BACKOFFICE_LOGOUT');
      localStorage.removeItem('t-backoffice');
      
      let auth0Client = await initAuth0Client()
      
      auth0Client.logout({
        returnTo: window.location.origin,
      });

    };

    if (isManuel) {
      axios({ url: 'auth/logout', method: 'POST' }).finally(() => {
        clear_tokens();
      });
    } else {
      clear_tokens();
    }
  }),

  auth_register: ({ commit, dispatch }, userData) => new Promise((resolve, reject) => {
    axios.post('/auth/register', userData)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp);
        } else if (resp.status === 409) {
          reject(resp);
        }
      });
  }),

  logout_backoffice: ({ commit, dispatch }) => new Promise((resolve, reject) => {
    axios.post('/backoffice/logout')
    .then((resp) => {
      if (resp.status === 200) {
        commit('BACKOFFICE_LOGOUT');
        localStorage.removeItem('t-backoffice');
        resolve(resp);
      } else {
        reject(resp);
      }
    });
  }),

  login_backoffice:( { commit, dispatch }, token) => new Promise((resolve, reject) => {
    commit('BACKOFFICE_LOGIN', token);
    localStorage.setItem('t-backoffice', token);
    resolve();
  }),
};

export default {
  state,
  getters,
  mutations,
  actions,
};